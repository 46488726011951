import { assign, createMachine } from "xstate"
import { addMonths } from "date-fns"
import { defaultMonthlyGivingAmount } from "../utils/helpers"

export interface LanguageContext {
  lang: string
}

export const languageMachine = createMachine(
  {
    id: "languageMachine",
    initial: "loading",
    context: {
      lang: "en-US"
    },
    states: {
      loading: {
        on: {
          CHANGE: {
            target: "loaded",
            actions: ["onChange"]
          }
        }
      },
      loaded: {}
    }
  },
  {
    actions: {
      onChange: assign({
        lang: (_ctx, e) => {
          // @ts-ignore
          return e.value
        }
      })
    }
  }
)

export interface DonationAmountContext {
  donationAmount: null | string
  donationAmountSelected: null | string
  errors: null | Array<string>
}

export const donationAmountMachine = createMachine(
  {
    id: "donationAmountMachine",
    initial: "editing",
    context: {
      donationAmount: null,
      donationAmountSelected: null,
      errors: null
    },
    states: {
      editing: {
        on: {
          CHANGE: {
            target: "",
            actions: ["onChange"]
          },
          SUBMIT: "submitting",
          RESET: "resetting"
        }
      },
      submitting: {
        invoke: {
          src: "onSubmit",
          onDone: "success",
          onError: {
            target: "error",
            actions: "onError"
          }
        }
      },
      resetting: {
        entry: assign({
          errors: null,
          donationAmount: "",
          donationAmountSelected: ""
        }),
        always: "editing"
      },
      success: {},
      error: {}
    }
  },
  {
    actions: {
      onChange: assign({
        donationAmountSelected: (ctx, e) => {
          // @ts-ignore
          return e.value.donationAmountSelected
        },
        donationAmount: (ctx, e) => {
          // @ts-ignore
          return e.value.donationAmount
        }
      })
    }
  }
)

export interface RecurringContext {
  values: {
    amount: string
    ministryPartnerChecked: boolean
    recurring: boolean
    recurOnDay: string
    recurStartDate: Date
  }
}

function recurStartDate(recurOnDay: string) {
  const recurDay = parseInt(recurOnDay)
  const today = new Date()
  // Start with recurDate on the specified day of next month
  let recurDate = new Date(today.getFullYear(), today.getMonth(), recurDay)
  recurDate = addMonths(recurDate, 1)
  // If recurDate is less than minimumDays from today, move it to the following month
  const minimumDays = 15
  if ((recurDate.getTime() - today.getTime())/(1000 * 60 * 60 * 24) < minimumDays) {
    recurDate = addMonths(recurDate, 1)
  }
  return recurDate
}

export const recurringMachine = createMachine(
  {
    id: "recurringMachine",
    initial: "editing",
    context: {
      values: {
        amount: defaultMonthlyGivingAmount.toString(),
        ministryPartnerChecked: false,
        recurring: false,
        recurOnDay: "1",
        recurStartDate: recurStartDate("1")
      },
      errors: null
    },
    states: {
      editing: {
        on: {
          CHANGE: {
            target: "",
            actions: ["onChange"]
          },
          SUBMIT: "submitting",
          RESET: "resetting"
        }
      },
      submitting: {
        invoke: {
          src: "onSubmit",
          onDone: "success",
          onError: {
            target: "error",
            actions: "onError"
          }
        }
      },
      resetting: {
        entry: assign({
          errors: null,
          values: () => ({
            amount: defaultMonthlyGivingAmount.toString(),
            ministryPartnerChecked: false,
            recurring: false,
            recurOnDay: "1",
            recurStartDate: recurStartDate("1")
          })
        }),
        always: "editing"
      },
      error: {},
      success: {}
    }
  },
  {
    actions: {
      onChange: assign({
        values: (ctx, e) => {
          // @ts-ignore
          const {key, value} = e
          const newStartDate = (key === "recurOnDay") ? {recurStartDate: recurStartDate(value)} : {}
          return {
            ...ctx.values,
            ...newStartDate,
            [key]: value
          }
        }
      }),
      onError: assign({
        errors: (ctx, e) => ({
          ...ctx.errors,
          // @ts-ignore
          [e.key]: e.errorText
        })
      })
    }
  }
)

export interface ProductCodesContext {
  product_codes: string[],
  selectedProduct: any
}

export const productCodesMachine = createMachine(
  {
    id: "productCodesMachine",
    initial: "editing",
    context: {
      product_codes: [],
      selectedProduct: null,
    },
    states: {
      editing: {
        on: {
          CHANGE: {
            target: "",
            actions: ["onChange"]
          }
        }
      }
    }
  },
  {
    actions: {
      onChange: assign({
        selectedProduct: (ctx, event) => {
          // @ts-ignore
          return event.selectedProduct
        },
        product_codes: (ctx, event) => {
          // @ts-ignore
          const { multiselect = false, value } = event

          if (multiselect) {
            const isCurrentlySelected = ctx.product_codes.find(
              (code) => code === value
            )

            if (isCurrentlySelected) {
              return ctx.product_codes.filter((code) => code !== value)
            } else {
              return ctx.product_codes.concat(value)
            }
          } else {
            return [value]
          }
        }
      })
    }
  }
)

export const paymentMethodsMachine = createMachine(
  {
    id: "paymentMethodsMachine",
    initial: "editing",
    context: {
      userPaymentMethods: null,
      selectedPaymentMethod: null
    },
    states: {
      editing: {
        on: {
          SET_SELECTED_PAYMENT_METHOD: {
            target: "",
            actions: ["setSelectedPaymentMethod"]
          },
          SET_USER_PAYMENT_METHODS: {
            target: "",
            actions: ["setUserPaymentMethods"]
          }
        }
      }
    }
  },
  {
    actions: {
      setUserPaymentMethods: assign({
        userPaymentMethods: (_ctx, event) => {
          // @ts-ignore
          return event.data
        }
      }),
      setSelectedPaymentMethod: assign({
        selectedPaymentMethod: (_ctx, event) => {
          // @ts-ignore
          return event.data
        }
      })
    }
  }
)
